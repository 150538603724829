import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from 'src/app/utils/base.service';

@Injectable({
  providedIn: 'root'
})
export class SlotsService {

  constructor(private httpClient: HttpClient, private baseService: BaseService) { }

  doLogOut(): void {
    this.baseService.logOut();
  }

  getBranchApptConfig(): any {
    const user = this.baseService.getClientInfo();
    // const branchid = user.facilitybranchid;
    const branchid = 1;
    return this.httpClient.get(this.baseService.appointmentURL() + `booking/configuration/${branchid}/branch`);
  }

  getServicePlaces(): any {
    return this.httpClient.get(this.baseService.appointmentURL() + 'serviceplace/resource-categories/serviceplaces');
  }

  getServices(serviceplaceid): any {
    return this.httpClient.get(
      this.baseService.appointmentURL() + 'service/resource-categories/' + serviceplaceid + '/services'
    );
  }

  getResources(serviceplaceid, serviceid, resourcetypeid, resourceCategID): any {
    return this.httpClient.get(
      this.baseService.appointmentURL() + 'resource/service/' + serviceplaceid + '/' + serviceid + '/' + resourcetypeid + '/' + resourceCategID + '/resources'
    );
  }

  getSlots(params): any {
    let urlParams2 = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const val = params[key];
        console.log(val);
        if (val === 0 || key === 'serviceplaceid' || val === null) {
          // delete params[key];
        } else {
          if (val !== 0) {
            urlParams2 = urlParams2.append(key, val);
          }
        }
      }
    }

    return this.httpClient.get(
      this.baseService.appointmentURL() + 'resource/duty-roaster/' + params.serviceplaceid, { params: urlParams2 }
    );
  }

  bookAppointment(info): any {
    const user = this.baseService.getUserData();
    info.createuserid = user.userid;
    return this.httpClient.post(this.baseService.appointmentURL() + 'appointment-booking', info);
  }

  getServiceFee(params): any {
    // params.branchid = this.baseService.getUserData().branch;
    return this.httpClient.post(this.baseService.dailyAttendanceURL() + 'services-request-unit-fee', params);
  }

  removeService(params): any {
    return this.httpClient.post(this.baseService.dailyAttendanceURL() + 'temp-service/delete', params);
  }

  searchPatient(params): any {
    return this.httpClient.get(this.baseService.dailyAttendanceURL() + 'patient/phone-or-opd/search?' + params.param + '=' + params.search);
  }

  updateInfo(params): any {
    return this.httpClient.post(this.baseService.appointmentURL() + 'patient-profile/update', params);
  }

  checkDailyAttendance(info): any {
    const user = this.baseService.getUserData();
    info.createuserid = user.id;
    // info.branchid = user.branch;
    info.branchid = 1;

    return this.httpClient.post(this.baseService.dailyAttendanceURL() + 'check', info);
  }

  getPatientSponsors(patientID): any {
    return this.httpClient.get(this.baseService.dailyAttendanceURL() + 'patientsponsor/' + patientID);
  }

  saveApptBooking(info): any {
    // const user = this.baseService.getUserData();
    const user = this.baseService.getClientInfo();
    // info.serviceRequest.branchid = user.facilitybranchid;
    info.serviceRequest.branchid = 1;
    info.serviceRequest.createuserid = user.userid;
    // info.serviceRequest.requesterid = user.userid;
    info.userid = user.userid;
    info.serviceRequest.requestlines.forEach(reqline => {
      reqline.requesterid = user.userid;
    });
    return this.httpClient.post(this.baseService.appointmentURL() + 'appointment-booking/on-site', info);
  }

  saveApptBookingNew(info): any {
    // const user = this.baseService.getUserData();
    const user = this.baseService.getClientInfo();
    // info.branchid = user.facilitybranchid;
    info.branchid = 1;
    info.createuserid = user.userid;
    /* info.requesterid = user.id;
    info.userid = user.id; */
    info.appointmentItems.forEach(reqline => {
      reqline.requesterid = user.userid;
    });
    return this.httpClient.post(this.baseService.appointmentURL() + 'appointment-booking/online', info);
  }

  mobileMoneyPayment(pdata): any {
    const user = this.baseService.getClientInfo();
    // pdata.branchid = user.facilitybranchid;
    pdata.branchid = 1;
    return this.httpClient.post(this.baseService.billingUrl() + 'mobile-money-payments', pdata);
  }
}
